import React from "react";
import classNames from "classnames";
import { ExternalLink } from "components/navigation";
import { SocialMediaLinkType } from "constants/enums";
import { getSocialMediaIcon } from "modules/helpers/icons";

const SocialMediaLink = ({
  type,
  value,
  customIcon = null,
  className,
  children,
  disabled,
  ...props
}) => {
  if (!value || !type) return null;

  let icon = getSocialMediaIcon(type);
  if (customIcon) icon = customIcon;

  return (
    <div
      className={classNames("social-media-link", { disabled }, className)}
      {...props}
    >
      <ExternalLink href={value}>
        <img
          src={icon}
          title={type != SocialMediaLinkType.Custom ? type : null}
        />
      </ExternalLink>
      {children}
    </div>
  );
};

export default SocialMediaLink;
