import { apiPost } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/REGISTER";

const ActionTypes = {
  RegisterUser: `${NAMESPACE}/REGISTER_USER`,
};

export const registerUser = (data) =>
  apiPost(ActionTypes.RegisterUser, "users/register", data, {
    auth: false,
    preventErrorNotification: true,
  });
