import { ContactPointIcon, SocialMediaLinkType } from "constants/enums";
import PhoneIcon from "assets/icons/phone.svg";
import MobileIcon from "assets/icons/mobile.svg";
import EmailIcon from "assets/icons/email.svg";
import SkypeIcon from "assets/icons/skype.svg";
import WebIcon from "assets/icons/web.svg";
import LinkedInIcon from "assets/icons/socialMedia/linkedin.svg";
import TwitterIcon from "assets/icons/socialMedia/twitter.svg";
import InstagramIcon from "assets/icons/socialMedia/instagram.svg";
import FacebookIcon from "assets/icons/socialMedia/facebook.svg";

export function getContactPointIcon(contactPointType) {
  switch (contactPointType) {
    case ContactPointIcon.Phone:
      return PhoneIcon;
    case ContactPointIcon.Mobile:
      return MobileIcon;
    case ContactPointIcon.Email:
      return EmailIcon;
    case ContactPointIcon.Skype:
      return SkypeIcon;
    case ContactPointIcon.Website:
      return WebIcon;
    default:
      return null;
  }
}

export function getSocialMediaIcon(socialMediaType) {
  switch (socialMediaType) {
    case SocialMediaLinkType.LinkedIn:
      return LinkedInIcon;
    case SocialMediaLinkType.Twitter:
      return TwitterIcon;
    case SocialMediaLinkType.Facebook:
      return FacebookIcon;
    case SocialMediaLinkType.Instagram:
      return InstagramIcon;
  }
}
