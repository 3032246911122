import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { UserTitleDropdown } from "components/forms";
import { SystemRoutes } from "constants/routes";
import { registerUser } from "./actions";

const registerErrors = {
  2008: "This email address is already registered; please continue to log in using the link below. If you have forgotten your password you can reset it, or contact enquiries@wiltonpark.org.uk for further assistance and provide the name of the event that you are invited to.",
  2016: "This email address is already registered, but has not been verified. Please click on the link in your verification email, or contact enquiries@wiltonpark.org.uk for further assistance and provide the name of the event that you are invited to.",
  2017: "If you already have an account with us, please check your email to complete your user verification, or contact enquiries@wiltonpark.org.uk if you need any further assistance.",
};

const Register = () => {
  const [requestSent, setRequestSent] = useState(false);

  if (requestSent) {
    return (
      <div>
        <p>Your registration request has been sent.</p>
        <p>
          We will let you know when this has been approved, so please keep an
          eye on your inbox and check your junk folder.
        </p>
        <div style={{ marginTop: 20 }}>
          <Button primary fluid as={Link} to={SystemRoutes.Login}>
            Continue
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FormBuilder
      submitAction={registerUser}
      onSubmitted={() => setRequestSent(true)}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Form {...formProps}>
          <UserTitleDropdown
            field="title"
            label="Title"
            placeholder="Enter your title"
            disabled={submitting && slowSubmitting}
            autoFocus
            fluid
          />
          <Form.Input
            field="firstName"
            label="First Name"
            placeholder="Enter your first name"
            disabled={submitting && slowSubmitting}
            required
            fluid
          />
          <Form.Input
            field="lastName"
            label="Last Name"
            placeholder="Enter your last name"
            disabled={submitting && slowSubmitting}
            required
            fluid
          />
          <Form.Email
            field="email"
            label="Email"
            placeholder="Enter your email address"
            disabled={submitting && slowSubmitting}
            required
            fluid
          />
          <Form.TextArea
            field="eventComments"
            label="Please let us know the event you are interested in?"
            disabled={submitting && slowSubmitting}
            required
            requiredError="Please tell us about the event you're interested in"
            fluid
          />
          <Button
            primary
            onClick={events.onSubmit}
            disabled={submitting}
            loading={submitting && slowSubmitting}
            fluid
            size="large"
          >
            Sign up
          </Button>
          <ErrorMessage
            header="Sign up error"
            error={error}
            overrideMessages={registerErrors}
            omitCodes
          />
          <p>
            Return to <Link to={SystemRoutes.Login}>Log In</Link>
          </p>
          <p>
            Forgot Password?{" "}
            <Link to={SystemRoutes.ForgottenPassword}>Reset it</Link>
          </p>
        </Form>
      )}
    />
  );
};

export default Register;
